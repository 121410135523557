<template>
  <div class="scenario-share">
    <el-dialog
      v-model="visible"
      title="Share To"
      width="700px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="visible"
    >
      <div class="scenario-share-bd">
        <el-table
          ref="multipleTableRef"
          :data="dataList"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column property="name" label="This Store" />
        </el-table>
      </div>
      <template slot="footer">
        <div class="scenario-share-ft">
          <el-button @click="handleClose">Cancel</el-button>
          <el-button type="success" @click="submitForm()">Save</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { agentMerchantList, scenarioShare} from "@/libs/api/scenario-config";
export default {
  name:'scenarioShare',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      tableData:[
        {
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-02',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-04',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
      ],
      dataList:[],
      selectedList:[]
    }
  },
  created(){
    this.getData()
  },
  methods: {
    async getData(){
      let params = {
        name:'',
        id:'',
        isDisplayMerchant:''
      }
      let { data } = await agentMerchantList(params);
      console.log('分享获取下级商户',data)
      if(data.state){
        this.dataList = data.data.treeList
      }else{
        this.$message({
          message: data.message,
          type:'warning',
        });
      }
    },
    handleClose(){
      this.$emit('close')
    },
    handleSelectionChange(val){
      console.log('多选',val)
      this.selectedList = val
    },
    submitForm(){
      if(this.selectedList.length === 0) {
        this.$message({
          message: '至少选择一个下级分享',
          type:'warning',
        });
      } else {
        this.scenarioShare()
      }
    },
    // 分享
    async scenarioShare(){
      let arr = this.selectedList.map((item)=>({
        id:item.id,
        type:item.type
      }))
      let params = {
        scenarioId:'',
        customerList:arr
      }
      console.log('分享的参数',params)
      return
      let { data } = await scenarioShare(params);
      if(data.state){
        this.$emit('close')
      }else{
        this.$message({
          message: data.message,
          type:'warning',
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .scenario-share{
    .scenario-share-bd{

    }

    .scenario-share-ft{
    // 重置样式
      .el-button--success{
        background: #35B871;
      }
      .el-input__inner:focus {
        border-color:#35B871;
      }
    }
  }
</style>