<template>
  <div class="common-table-list scenario-config">
    <!-- <pageLocation :location="location"></pageLocation> -->
    <div class="location">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link>
      <span> > {{ $t('System Configuration')}} > </span>
      <span style="color:#3461FF">{{ $t('Scenario Configuration') }}</span>
    </div>
    <scenarioTop></scenarioTop>
    <div class="scenario-config-bd">
      <div class="scenario-config-left card">
        <scenarioLeft></scenarioLeft>
      </div>
      <div class="scenario-config-right card">
        <el-scrollbar style="height:100%" ref="scrollbarRight">
          <div v-if="store.currentEditFormType == 'scenario'" class="card">
            <scenarioForm></scenarioForm>
          </div>
          <div v-else-if="store.currentEditFormType == 'group'" class="card">
            <scenarioGroupForm></scenarioGroupForm>
          </div>
          <div v-else-if="store.currentEditFormType == 'fields'" class="card">
            <scenarioFieldsForm></scenarioFieldsForm>
          </div>
        </el-scrollbar>
        <!-- <div style="height:100px"></div> -->
      </div>
    </div>
    
  </div>
</template>
<script>
import pageLocation from './components/pageLocation.vue'
import scenarioLeft from './components/scenarioLeft.vue'
import scenarioTop from './components/scenarioTop.vue'
import scenarioForm from './components/formScenario.vue'
import scenarioGroupForm from './components/formGroup.vue'
import scenarioFieldsForm from './components/formFields.vue'
import { store }  from './store/index'
export default {
  components: {
    pageLocation,
    scenarioLeft,
    scenarioTop,
    scenarioForm,
    scenarioGroupForm,
    scenarioFieldsForm
  },
  data() {
    return {
      store,
      location:'Welcome > System Config > Scenario Configuration', 
    }
  },
  created() {
    
    // store.getScenarioList();
  },
  mounted(){
    store.injectScrollbarRightTop(this.setScrollbarRightTop)
  },
  methods: {
    setScrollbarRightTop(){
      console.log('滚动条',this.$refs.scrollbarRight)
      this.$refs.scrollbarRight.wrap.scrollTop = 0
      // setScrollTop
    }
  },
}
</script>
<style lang="scss" scoped>
  .scenario-config{
    .location{
      margin-bottom: 20px;
    }
    // font-size: 16px;
    .el-scrollbar__wrap{
      overflow-x: hidden;
    }
  }
  .scenario-config-bd{
    height: 80vh;
    display: flex;
    position: relative;
  }

  .scenario-config-left{
    height: 100%;
    width: 260px;
    margin: 0 1.2rem 2rem 0;
    min-width: 260px;
    position:relative;
  }
  .scenario-config-right{
    height: 100%;
    flex: 1;
    // background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
    border-radius: 12px;
  }
</style>