const handleNumber = {
  custom1(x){
    // 四舍五入，保留整数
    return Number(x).toFixed(0)
  },
  custom2(x){
    // 四舍五入，保留两位小数
    return Number(x).toFixed(2)
  },
  custom3(x){
    // 四舍五入，保留整数，并加上千分符
    let num = Number(Number(x).toFixed(0)).toLocaleString();
    return num
  },
  custom4(x){
    console.log(Number(x).toFixed(2))
    // 四舍五入，保留两位小数，并加上千分符
    // let num = Number(Number(x).toFixed(2)).toLocaleString();
    let num = Number(x).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    return num
  },
  custom5(x){
    // 四舍五入，保留整数，并加上千分符,并加上 ￥
    let num = Number(Number(x).toFixed(0)).toLocaleString("zh-CN", {style:"currency", currency:"CNY"})
    num = num.slice(0,num.length-3)
    console.log('处理custom5',num,typeof(num))
    return num
  },
  custom6(x){
    // 四舍五入，保留两位小数，并加上千分符,并加上 ￥
    let num = Number(Number(x).toFixed(2)).toLocaleString("zh-CN", {style:"currency", currency:"CNY"})
    // num = num.slice(0,num.length-3)
    console.log('处理custom6',num,typeof(num))
    return num
  },
  custom7(x){
    // 四舍五入，保留整数，并加上千分符,并加上 $
    let num = Number(Number(x).toFixed(0)).toLocaleString("en-US", {style:"currency", currency:"USD"})
    num = num.slice(0,num.length-3)
    return num
  },
  custom8(x){
    // 四舍五入，保留两位小数，并加上千分符,并加上 $
    let num = Number(Number(x).toFixed(2)).toLocaleString("en-US", {style:"currency", currency:"USD"})
    return num
  },
  custom9(x){
    return Math.trunc(x)
  },
  custom10(x){
    return Math.trunc(x)
  },
  ceil(x){
    // 对 x 进行上舍入。向上取整计算
    return Math.ceil(x)
  },
  floor(x){
    // 对 x 进行下舍入。向下取整计算
    return Math.floor(x)
  },
  round(x){
    // 四舍五入。把一个数字舍入为最接近的整数
    return Math.round(x)
  },
  trunc(x){
    // 将数字的小数部分去掉，只保留整数部分。
    return Math.trunc(x)
  },

}

export default handleNumber
