<template>
  <div class="fields-sort">
    <el-dialog
      v-model="dialogVisible"
      title="字段排序"
      width="500px"
      :before-close="close"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
    >
    <div>
      <div class="tree-container">
        <el-scrollbar style="height:100%">
          <el-tree
            :data="dataList"
            :props="defaultProps"
            node-key="id"
            default-expand-all
            @node-drag-start="handleDragStart"
            @node-drag-enter="handleDragEnter"
            @node-drag-leave="handleDragLeave"
            @node-drag-over="handleDragOver"
            @node-drag-end="handleDragEnd"
            @node-drop="handleDrop"
            draggable
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span v-if="node.level === 2" class="fields-add" @click.stop="sort(data)">
                <i class="el-icon-menu"></i>
              </span>
              <span>{{ node.label }}</span>
              
            </span>
          </el-tree>
        </el-scrollbar>
      </div>

      <div class="btn-section">
        <el-button size="small" color="#28C76F" @click="close">{{$t('button.Cancel')}}</el-button>
        <el-button size="small" color="#28C76F" type="success" @click="onSubmit">保存排序</el-button>
      </div>

    </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    name:'',
    components: {
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        dialogVisible:false,
        dataList:[],
        defaultProps:{
          children: 'children',
          label: 'defaultTextName'
        },

      }
    },
    created(){
      
    },
    mounted(){
      
    },
    methods: {
      // 打开弹窗
      open(data){
        this.dialogVisible = true
        // data.children
        let newData =[...data.children]
        this.dataList = newData
      },
      // 关闭弹窗
      close(){
        this.dialogVisible = false
      },
      // 判断节点能否被拖拽
      allowDrag(draggingNode) {
        console.log('---判断节点能否被拖拽---draggingNode',draggingNode)
        if(draggingNode.level === 1){
          return false
        } else {
          return true
        }
        // return draggingNode.data.label.indexOf('三级 3-2-2') === -1;
      },
      // 拖拽时判定目标节点能否被放置。
      allowDrop(draggingNode, dropNode, type) {
        console.log('---拖拽时判定目标节点能否被放置---draggingNode',draggingNode, dropNode, type)
        if(dropNode.level === 1){
          return type === 'inner'? true :false
        } else {
          return type === 'inner'? false :true
        }
        
        // if (dropNode.data.label === '二级 3-1') {
        //   return type !== 'inner';
        // } else {
        //   return true;
        // }
      },
      onSubmit(){
        
      },
      handleDragStart(node, ev) {
        console.log('drag start', node);
      },
      handleDragEnter(draggingNode, dropNode, ev) {
        console.log('tree drag enter: ', dropNode.label);
      },
      handleDragLeave(draggingNode, dropNode, ev) {
        console.log('tree drag leave: ', dropNode.label);
      },
      handleDragOver(draggingNode, dropNode, ev) {
        console.log('tree drag over: ', dropNode.label);
      },
      handleDragEnd(draggingNode, dropNode, dropType, ev) {
        console.log('tree drag end: ', dropNode && dropNode.label, dropType);
      },
      handleDrop(draggingNode, dropNode, dropType, ev) {
        console.log('tree drop: ', dropNode.label, dropType);
      },
      
      showmessage(){
        this.$message({
          message: 'please select img!',
          type:'success',
        });
        this.$message({
          message: 'please select img!',
          type:'warning',
        });
        this.$message.error('错误提示')
      },
    }
  }
</script>
<style lang="scss" scoped>
.fields-sort{
  .tree-container{
    height: 60vh;
  }
  .btn-section{
    margin: 0 -20px;
    padding: 15px 0 0 0;
    text-align: center;
    background: #fff;
    border-top: 1px solid #E5E5E5;
    // box-shadow: 0px -2px 2px 0px rgb(0 0 0 / 10%);
  }

  // 重置样式
  ::v-deep .el-dialog__body{
    padding: 20px;
  }
  ::v-deep  .el-tree-node__content{
    cursor: grab;
  }
}
</style>