<template>
  <div class="scenario-form-component">
    <div class="scenario-form-component-hd">
      <img class="scene-title-icon" src="@/assets/img/scene_title_icon.png" />
      <span style="margin-left: 10px;">{{store.groupFormData.defaultTextName ? $t('scenarioConfig.Edit Group') : $t('scenarioConfig.Add Group')}}</span>
      <el-divider />
    </div>
    <div class="scenario-form-component-bd">
      <el-form
        ref="groupFormData" 
        label-width="120px"
        style="max-width: 460px"
        :model="store.groupFormData"
        size="small"
        :rules="rules"
      >
        <el-form-item 
          :label="$t('scenarioConfig.Group Name')"
          prop="defaultTextName"
        >
          <el-input 
            v-model="store.groupFormData.defaultTextName" 
            :placeholder="$t('scenarioConfig.Group Name')"
            :disabled="!store.groupFormData.isDefault" 
          />
        </el-form-item>
        <el-form-item :label="$t('scenarioConfig.Language')">
          <el-input  :placeholder="$t('scenarioConfig.Please Input')" readonly>
            <template #append>
              <div>{{$t('scenarioConfig.Details')}}...</div>
              <div class="scenario-form-language" @click="selectLanguage">{{$t('scenarioConfig.Details')}}...</div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button color="#28C76F" type="success" @click="submitFormGroup">{{$t('button.Save')}}</el-button>
        </el-form-item>
      
      </el-form>
    </div>

    <!-- 语言弹窗 -->
    <languageSelect 
      :visible="dialogVisible"
      :languageList="store.groupFormData.languageList"
      @close="closeLanguage"
      @message="setLanguageConfig"
    >
    </languageSelect>

  </div>
</template>
<script>
// 接口
import { createScenarioGroup, updateScenarioGroup } from "@/libs/api/scenario-config";
import languageSelect from './languageSelect.vue'
import { store } from "../store/index"
let loading;

export default {
  components: {
    languageSelect
  },
  props: {
  },
  data() {
    return {
      store,
      rules: {
        defaultTextName: [
          { required: true, message: this.$t('formValidate.required fields') , trigger: 'blur'},
          { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
        ]
      },
      dialogVisible:false,
      isModifyLanguage: false
    }
  },
  created(){
    // this.getData()
  },

  methods: {
    // 表单校验
    submitFormGroup(){
      this.isModifyLanguage = false
      console.log(this.$refs.groupFormData)
      loading = this.$loading({
        text:"loading...",
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.$refs.groupFormData.validate( valid => {
        if (valid) {
          console.log('表单校验通过-store.scenarioFormData',store.scenarioFormData)
          if(store.groupFormData.groupId){
            this.groupUpdate()
          } else {
            this.groupAdd() 
          }
          
        } else {
          loading.close()
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 分组新增
    async groupAdd(){
      let params = {
        scenarioId: store.groupBasicData.scenarioId,
        defaultTextName: store.groupFormData.defaultTextName,
        displayOrder: store.groupFormData.displayOrder,
        languageList: store.groupFormData.languageList,
      }
      let { data } = await createScenarioGroup(params)
      console.log('【新增分组】接口返回', data)
      if(data.state){
        this.$message({
          message: this.$t('commonMessage.CreateSucess'),
          type:'success',
        });
        store.reloadScenarioListGetFunction()
        // store.getScenarioList()
      } else {
        this.$message({
          message: data.message,
          type:'warning',
        });
        // alert(data.message)
      }
      setTimeout(() => {
        loading.close()
      },500)

    },
    //分组修改
    async groupUpdate(){
      let {  groupId, defaultTextName, displayOrder, languageList } = store.groupFormData
      let params = {
        scenarioId:store.groupBasicData.scenarioId,
        groupId,
        defaultTextName,
        displayOrder,
        languageList,
        updateType:this.isModifyLanguage ? 1: 0
      }
      let { data } = await updateScenarioGroup(params)
      console.log('修改场景接口返回', data)
      if(data.state){
        this.$message({
          message: this.$t('commonMessage.ModifySucess'),
          type:'success',
        });
        store.reloadScenarioListGetFunction()
        // store.getScenarioList()
      } else {
        this.$message({
          message: data.message,
          type:'warning',
        });
      }
      setTimeout(() => {
        loading.close()
      },500)

    },
    // 选择语言弹窗
    selectLanguage(){
      this.dialogVisible = true;
      console.log('选择语言',this.dialogVisible)
    },
    // 关闭语言弹窗
    closeLanguage(){
      console.log('测试closeLanguage')
      this.dialogVisible = false;
    },
    setLanguageConfig(data){
      console.log('form表单接收的data',data)
      store.groupFormData.languageList = data
      if(store.groupFormData.groupId){
        this.isModifyLanguage = true
        this.groupUpdate()
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.scenario-form-component{
  // min-height: 73vh;
  .scene-title-icon{
    width: 15px;
    height: 16px;
  }
  .scenario-form-component-hd{
    height: 50px;
    line-height: 50px;
    padding: 0 28px;
    .el-divider--horizontal{
      margin: 0;
    }
  }
  .scenario-form-component-bd{
    display: flex;
    justify-content: center;
    padding: 36px 0 20px;
  }
  .scenario-form-language{
    background: #3461FF;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 3px;
    padding: 7px 20px;
    cursor: pointer;
  }

  // 重置样式
  .el-form-item__label {
    color: #999;
    font-size: 14px!important;
  }
  .el-button--success{
    background: #35B871;
    box-shadow: 0px 1px 15px 0px rgba(53,184,113,0.55);
  }
  .el-input__inner:focus {
    border-color:#35B871;
  }
  .el-button{
    width: 100px;
  }
}
</style>