<template>
  <div class="language-component">
    <el-dialog
      v-model="visible"
      :title="$t('scenarioConfig.Language')"
      width="700px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="visible"
    >
      <div class="language-bd">
        <el-form
          ref="languageForm" 
          :model="languageFormData"
          label-width="160px"
          style="max-width: 700px"
          size="small"
        >
          <el-form-item 
            v-for="(item,index) in languageFormData.languageList" 
            :label="item.name" 
            :key="index"
            :prop="'languageList.' + index + '.text'"
            :rules="[{ max: 100, message: '最长不能超过100个字符', trigger: 'blur'}]"
          >
            <el-input 
              v-model="item.text" 
              placeholder="" 
              class="input-with-img"
              clearable
            >
              <template #prepend>
                <img class="language-img" :src="item.logoPath || ''" />
                <!-- <i class="el-icon-menu" size="14"></i> -->
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <div class="language-ft">
          <el-button size="small" @click="handleClose">{{$t('button.Cancel')}}</el-button>
          <el-button size="small" type="success" @click="submitForm()">{{$t('button.Confirm')}}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
// import { reactive } from '@vue/composition-api'
import { getListli } from "@/libs/api/scene";

export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    languageList:Array
  },
  data() {
    return {
      languageFormData:{
        languageList: [
          {
            languageId: "",
            language: "Chinese Simplified", //languageCode
            text: ""
          }
        ],
      }
      
    }
  },
  created() {
    console.log('created---language',this.computedLanguageList)
    if(this.computedLanguageList.length){
      this.handlePropsData(this.computedLanguageList)
    } else {
      this.getLanguageList();
    }
  },
  computed:{
    computedLanguageList: function() {
      console.log('----computed-languageList')
      return this.languageList
    }
  },
  watch: {
    computedLanguageList: function(val) {
      console.log('----watch-computedLanguageList',val)
      if(val.length === 0){
        this.getLanguageList()
      } else {
        this.handlePropsData(val)
      }
    }
  },
  methods: {
    // 获取语言列表
    async getLanguageList () {
      let { data } = await getListli();
      if(data.state){
        let list = data.data;
        list = list.map((value) => ({
          ...value,
          text:""
        }))
        localStorage.setItem("languageList", JSON.stringify({languageList: list}));
        this.languageFormData.languageList = list;
      } else {
        this.$message({
          message: data.message,
          type:'warning',
        });
      }
      
    },
    // 处理prpos数据
    handlePropsData (propsData) {
      let dataList  = JSON.parse(localStorage.getItem("languageList"));
      let localStorageData = dataList.languageList;
      console.log('查找到的缓存数据',localStorageData);
      console.log('处理prpos数据',propsData);
      if(propsData.length === 0) {
        this.languageFormData.languageList = localStorageData;
        return
      }
      for(var i=0; i< localStorageData.length; i++){
        // let index = propsData.findIndex(item => item.languageId == localStorageData[i].languageId)
        // let index = -1;
        // if(index>-1){
        //   localStorageData[i].text = propsData[index].text
        // } else {
        //   localStorageData[i].text = ""
        // }

        for(var j=0; j<propsData.length; j++){
          if (localStorageData[i].languageId === propsData[j].languageId) {
            localStorageData[i].text = propsData[j].text
          }
        }
      }
      console.log("处理后的localStorageData---",localStorageData)
      this.languageFormData.languageList = localStorageData
    },
    handleClose(){
      this.$emit('close');
    },
    // 保存数据
    submitForm(){
      console.log('提交表单')
      console.log('测试this.languageList',this.languageFormData.languageList)
      this.$refs.languageForm.validate( valid => {
        if (valid) {
          const iniArr = this.languageFormData.languageList.map((value) => ({
            languageId:value.languageId,
            language:value.languageCode,
            text:value.text,
          }))
          const arr = iniArr.filter(value => value.text !== "")
          console.log('emit--data',arr)
          this.$emit('close')
          this.$emit('message',arr)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>
<style lang="scss" scoped>
  .language-component{
    .language-bd{
      height: 240px;
      overflow-y: scroll;
      padding: 0 50px;
      .input-with-img .el-input-group__prepend {
        background-color: #fff;
      }
      .language-img{
        width: 28px;
        height: 28px;
      }
    }

    .language-ft{
      // 重置样式
      .el-button{
        width: 110px;
      }
    
    }
  }

</style>