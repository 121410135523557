<!-- <i18n src="../languages/index.json"></i18n> -->
<template>
  <div class="scenario-top-component card">
    <div class="scenario-top-card">
      <el-button type="primary" size="small" @click="addScenario">
        <i class="el-icon-plus" />
        {{$t('scenarioConfig.New Scenario')}}
      </el-button>
      <!-- <div v-if="userType== 201 || userType == 202">
        <el-button icon="el-icon-share" @click="open" type="info" plain size="small">{{$t('scenarioConfig.Share To')}}</el-button>
        <scenarioShare :visible="dialogVisible" @close="close"></scenarioShare>
      </div> -->
    </div>
  </div>
</template>
<script>
import scenarioShare from './scenarioShare.vue'
import { store } from "../store/index"
export default {
  name:'',
  components: {
    scenarioShare
  },
  data() {
    return {
      store,
      dialogVisible:false,
      userType:''
    }
  },
  created(){
    
  },
  mounted(){
    // this.role = localStorage.getItem("userData").role
    let userData = JSON.parse(localStorage.getItem("userData"))
    
    console.log('缓存获取userData',userData, sessionStorage.getItem("userType"))
    this.userType = userData.userType || sessionStorage.getItem("userType")

  },
  methods: {
    // 添加场景
    addScenario(){
      store.initCenarioFormData()
      store.setCustomData('currentEditFormType','scenario');
    },
    // 分享场景
    open(){
      this.dialogVisible = true
    },
    // 关闭分享弹窗
    close(){
      this.dialogVisible = false
    }

  }
}
</script>
<style lang="scss" scoped>
  .scenario-top-component{
    margin-bottom: 20px;
    .scenario-top-card {
      padding: 15px;
      // margin-bottom: 10px;
      // background: #FFFFFF;
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      .el-button--success {
        background: #35B871;
      }
    }
  }
  
</style>