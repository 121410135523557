<!-- <i18n src="../languages/index.json"></i18n> -->
<template>
  <div class="scenario-form-component">
    <div class="scenario-form-component-hd">
      <img class="scene-title-icon" src="@/assets/img/scene_title_icon.png" />
      <span style="margin-left: 10px;">{{store.scenarioFormData.scenarioKey ? $t('scenarioConfig.Edit Scenario') : $t('scenarioConfig.Add Scenario')}}</span>
      <el-divider />
    </div>
    <div class="scenario-form-component-bd">
      <el-form
        ref="scenarioforForm" 
        label-width="120px"
        style="max-width: 460px"
        :model="store.scenarioFormData"
        size="small"
      >
        <el-form-item 
          :label="$t('scenarioConfig.Scenario Name')"
          prop="defaultTextName"
          :rules="[
            { required: true, message: this.$t('formValidate.required fields') , trigger: 'blur'},
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'},
          ]"
        >
          <el-input 
            v-model="store.scenarioFormData.defaultTextName" 
            :placeholder="$t('scenarioConfig.Scenario Name')"
            :disabled="!store.scenarioFormData.isDefault"
          />
        </el-form-item>
        <el-form-item 
          :label="$t('scenarioConfig.Scenario Key')" 
          prop="scenarioKey"
          :rules="[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ]"
        >
          <el-input 
            v-model="store.scenarioFormData.scenarioKey" 
            :placeholder="$t('scenarioConfig.Scenario Key')"
            :disabled="!store.scenarioFormData.isDefault" 
          />
        </el-form-item>
        <el-form-item  :label="$t('scenarioConfig.Language')">
          <el-input  :placeholder="$t('scenarioConfig.Please Input')" readonly>
            <template #append>
              <div>{{$t('scenarioConfig.Details')}}...</div>
              <div class="scenario-form-language" @click="selectLanguage">{{$t('scenarioConfig.Details')}}...</div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button 
            color="#28C76F" 
            type="success"
            @click="submitForm"
          >{{$t('button.Save')}}</el-button>
        </el-form-item>
      
      </el-form>
    </div>

    <!-- 语言弹窗 -->
    <languageSelect 
      :visible="dialogVisible"
      :languageList="store.scenarioFormData.languageList"
      @close="closeLanguage"
      @message="setLanguageConfig"
    >
    </languageSelect>
  </div>
</template>
<script>
// 接口
import { addscenlis, scenlisupdate } from "@/libs/api/scenario-config";
import languageSelect from './languageSelect.vue'
import { store } from "../store/index"
let loading;

export default {
  components: {
    languageSelect
  },
  data() {
    return {
      store,
      rules: {
        defaultTextName123: [
          { required: true, message: this.$t('formValidate.required fields') , trigger: 'blur'},
          { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
        ],
        scenarioKey: [
          { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
          { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
        ]
      },
      dialogVisible:false,
      loadingInstance:null,
      isModifyLanguage: false
    }
  },
  created(){
    // this.getData()
  },

  methods: {
    // 校验是否是查找数字、字母及下划线。
    isValid(str) { 
      return /^\w+$/.test(str); 
    },
    // 表单校验
    submitForm(){
      this.isModifyLanguage = false
      console.log(this.$refs.scenarioforForm)
      loading = this.$loading({
        // fullscreen:false,
        text:"loading...",
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.$refs.scenarioforForm.validate( valid => {
        if (valid) {
          console.log('表单校验通过-store.scenarioFormData',store.scenarioFormData)
          // store.showLoading()
          if(store.scenarioFormData.scenarioId){
            this.scenlisupdate()
          } else {
            this.addscenlis() 
          }
          
        } else {
          loading.close()
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 场景新增
    async addscenlis(){
      let params = {
        scenarioKey: store.scenarioFormData.scenarioKey,
        defaultTextName: store.scenarioFormData.defaultTextName,
        languageList: store.scenarioFormData.languageList,
      }
      let { data } = await addscenlis(params)
      console.log('新增场景接口返回', data)
      if(data.state){
        this.$message({
          message: this.$t('commonMessage.CreateSucess'),
          type:'success',
        });
        store.reloadScenarioListGetFunction()
        // store.getScenarioList()
      } else {
        this.$message({
          message: data.message,
          type:'warning',
        });
        // alert(data.message)
      }
      setTimeout(() => {
        loading.close()
      },500)
    },
    //场景修改
    async scenlisupdate(){
      let { scenarioId, scenarioKey, defaultTextName, languageList } = store.scenarioFormData
      let params = {
        scenarioId,
        scenarioKey,
        defaultTextName,
        languageList,
        updateType:this.isModifyLanguage ? 1: 0
      }
      // let res = await this.$Api.updateScenario(params)
      let { data } = await scenlisupdate(params)
      console.log('修改场景接口返回', data)
      if(data.state){
        store.reloadScenarioListGetFunction()
        // store.getScenarioList()
        this.$message({
          message: this.$t('commonMessage.ModifySucess'),
          type:'success',
        });
      } else {
        this.$message.error(data.message);
      }
      setTimeout(() => {
        loading.close()
      },500)
      
      // this.fullscreenLoading = false;
    },
    // 选择语言弹窗
    selectLanguage(){
      this.dialogVisible = true;
      console.log('选择语言',this.dialogVisible)
    },
    // 关闭语言弹窗
    closeLanguage(){
      console.log('测试closeLanguage')
      this.dialogVisible = false;
    },
    setLanguageConfig(data){
      console.log('语言弹窗确定',data)
      store.scenarioFormData.languageList = data
      if(store.scenarioFormData.scenarioId){
        this.isModifyLanguage = true
        this.scenlisupdate()
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.scenario-form-component{
  // min-height: 73vh;
  .scene-title-icon{
    width: 15px;
    height: 16px;
  }
  .scenario-form-component-hd{
    height: 50px;
    line-height: 50px;
    padding: 0 28px;
    .el-divider--horizontal{
      margin: 0;
    }
  }
  .scenario-form-component-bd{
    display: flex;
    justify-content: center;
    padding: 36px 0 20px;
  }
  .scenario-form-language{
    background: #3461FF;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 3px;
    padding: 7px 20px;
    cursor: pointer;
  }

  // 重置样式
  .el-form-item__label {
    color: #999;
    font-size: 14px!important;
  }
  .el-button--success{
    background: #35B871;
    box-shadow: 0px 1px 15px 0px rgba(53,184,113,0.55);
  }
  .el-input__inner:focus {
    border-color:#35B871;
  }
  .el-button{
    width: 100px;
  }
}
</style>