import { reactive } from '@vue/composition-api'
import { scenlistsect } from "@/libs/api/scenario-config";
import { cloneDeep } from 'lodash';

export const store = reactive({
  searchValue:'',
  // 编辑、添加、删除成功后重新获取场景列表
  scenarioListGetFunction:null,
  setScenarioListGetFunction(fn){
    this.scenarioListGetFunction =  fn
  },
  reloadScenarioListGetFunction(){
    this.scenarioListGetFunction()
  },

  // 设置表单内容到顶部
  scrollbarRightTop:null,
  injectScrollbarRightTop(fn){
    this.scrollbarRightTop = fn
  },
  setScrollbarRightTop(){
    this.scrollbarRightTop()
  },
  // 场景表单--字段
  scenarioFormData: {
    scenarioId: '',
    defaultTextName: '',
    scenarioKey: '',
    isDefault: 1,
    languageList: [],
  },
  // 场景表单--字段修改
  setScenarioFormData(data){
    this.scenarioFormData = data
  },
  // 场景表单--字段初始化（清空已有的数据）
  initCenarioFormData(){
    let init ={
      scenarioId: '',
      defaultTextName: '',
      scenarioKey: '',
      isDefault: 1,
      languageList: [],
    }
    this.scenarioFormData = init
  },
  // 分组表单--字段
  groupFormData: {
    scenarioId: '', //场景ID
    groupId:'', //分组ID
    defaultTextName: '',
    displayOrder:'',
    isDefault: 1,
    languageList: [
      {
        languageId: "",
        language: "",
        text: ""
      }
    ],
  },
  // 分组基础信息
  groupBasicData:{
    scenarioId:'',
  },
  // 分组表单--清空
  clearGroupFormData(){
    let formData = {
      groupId:'',
      defaultTextName: '',
      // displayOrder:scenario.groupList.length,
      displayOrder: 0,
      isDefault: 1,
      languageList: []
    };
    this.groupFormData = formData
  },
  // 分组表单设置
  setGroupFormData(data){
    // let formData = {
    //   scenarioId:data.scenarioId,
    //   groupId:data.groupId,
    //   defaultTextName: data.defaultTextName,
    //   displayOrder: data.displayOrder,
    //   isDefault: data.isDefault,
    //   languageList: data.languageList,
    // };
    // this.groupFormData = formData
    this.groupFormData = data
  },
  // 字段表单--数据
  fieldsFormData:{
    common:{
      // scenarioId:'',//场景ID
      // groupId:'',//分组ID
      fieldId:'', // 字段ID
      type:'text',//字段类型，字段类型,text:文本 date:日期 number:数字 url: URL radio: 单选框 checkbox:复选框 img:图片
      // textKey:'', //
      fieldKey:'', //String，配置字段关键字
      defaultTextName:'', //String，english name 
      displayOrder: 0,  // 排序
      showInTemplate: true, //Integer，是否可以在模板上使用, 0:不显示 1显示,默认显示
      isVisible: true, //Integer，设置字段在商品页面是否可见， 0:不可见 1:可见, 默认可见
      canEdit: true, //Integer，字段在商品页面是否可编辑， 0:不可编辑 1:可编辑,默认可编辑
      isRequired: false, // Integer，字段在商品页面是否必填， 0:不是必填 1:必填，默认非必填
      batchUpdateVisible: false, // boolean，字段在商品页面是否支持被批量修改，默认：否
      isDefault:1, //"是否默认，0：默认，1：非默认"
      languageList: [
        {
          languageId: "",
          language: "",
          text: ""
        }
      ],
      defaultValue: '', // String,defaultValue
      maxLength: 100, // Integer, max length,默认为100
    },
    date:{
      format:'', // 配置的日期
    },
    img:{
      imageType:'0',
      defaultValue: '', // String,defaultValue
    },
    number:{
      isCustom: 0 , //is custom 0:非定制 1：定制
      defaultValue:'', // String,defaultValue
      keepDecimalPlaces: 2, //Integer, 保留小数位数, 默认为2位
      isDisplayThousands: true, //Integer, 显示千分位 ,  0:不显示 1：显示，默认为’是
      isDisplayPercentage: false, //Integer, 显示百分比，0:不显示 1：显示，默认为’否
      suffix: '', //String, 为数字设置后缀，非必填
      prefix: '', //String, 为数字设置前缀，非必填
      format:'', //自定义的格式
      testNumber:'',
      display:'',
    },
    checkbox:{
      defaultValue: '', // defaultValue
      checkBoxList:[
        {
          checked: false,
          checkBoxId:'', // 新建不传，修改必传
          text:'', // 文本
          displayOrder: 0, // 排序
        }
      ]
    },
    url:{
      url: '',
    },
    radio:{
    },
      
  },
  // 字段表单--数据清空
  clearFieldsFormData(){
    let formData = {
      common:{
        fieldId:'', // 字段ID
        type:'text',//字段类型，字段类型,text:文本 date:日期 number:数字 url: URL radio: 单选框 checkbox:复选框 img:图片
        fieldKey:'', //String，配置字段关键字
        defaultTextName:'', //String，english name 
        displayOrder: 0,  // 排序
        showInTemplate: true, //Integer，是否可以在模板上使用, 0:不显示 1显示,默认显示
        isVisible: true, //Integer，设置字段在商品页面是否可见， 0:不可见 1:可见, 默认可见
        canEdit: true, //Integer，字段在商品页面是否可编辑， 0:不可编辑 1:可编辑,默认可编辑
        isRequired: false, // Integer，字段在商品页面是否必填， 0:不是必填 1:必填，默认非必填
        batchUpdateVisible: false, // boolean，字段在商品页面是否支持被批量修改，默认：否
        isDefault:1,
        languageList: [],

        defaultValue: '', // String,defaultValue
        maxLength: 100, // Integer, max length,默认为100
      },
      date:{
        format:'', // 配置的日期
      },
      img:{
        imageType:'0',
        defaultValue: '', // String,defaultValue
      },
      number:{
        isCustom: 0 , //is custom 0:非定制 1：定制
        defaultValue:'', // String,defaultValue
        keepDecimalPlaces: 2 , //Integer, 保留小数位数, 默认为2位
        isDisplayThousands: true, //Integer, 显示千分位 ,  0:不显示 1：显示，默认为’是
        isDisplayPercentage: false, //Integer, 显示百分比，0:不显示 1：显示，默认为’否
        suffix: '', //String, 为数字设置后缀，非必填
        prefix: '', //String, 为数字设置前缀，非必填
        format:'',
        testNumber:'', 
        display:'',
      },
      checkbox:{
        defaultValue: '', // defaultValue
        checkBoxList:[
          {
            checkBoxId:'', // 新建不传，修改必传
            text:'', // 文本
            displayOrder: 0, // 排序
          }
        ]
      },
      url:{
        url: '',
      },
      radio:{
      },
    }
    this.fieldsFormData = formData
  },
  // 表单字段--设置
  setFieldsFormData(editData){
    let formData = {
      common: {
        fieldId:editData.fieldId, // 字段ID
        type:editData.type,//字段类型，字段类型,text:文本 date:日期 number:数字 url: URL radio: 单选框 checkbox:复选框 img:图片
        // textKey:editData.textKey, // ???默认名称 English-name
        fieldKey:editData.fieldKey, //field key
        defaultTextName:editData.defaultTextName, // english name 
        displayOrder:editData.displayOrder,  // 数字
        showInTemplate: editData.showInTemplate ? true : false, //show in template, 0:不显示 1显示,默认显示
        isVisible: editData.isVisible ? true : false, //is visible 0:不可见 1:可见, 默认可见
        canEdit: editData.canEdit ? true : false, // can edit 0:不可编辑 1:可编辑,默认可编辑
        isRequired: editData.isRequired ? true : false, // is required 0:不是必填 1:必填，默认非必填
        batchUpdateVisible: editData.batchUpdateVisible, // 是批量修改可见，默认：否
        isDefault: editData.isDefault,
        languageList:editData.languageList,

        defaultValue: editData.defaultValue, // default value
        maxLength: editData.maxLength, // max length,默认为100
      },
      date:{
        format:editData.format, // 配置的日期
      },
      img:{
        imageType:editData.imageType,
        defaultValue: editData.defaultValue,
      },
      number:{
        isCustom: editData.isCustom , //is custom 0:非定制 1：定制
        defaultValue: editData.defaultValue, 
        keepDecimalPlaces: editData.keepDecimalPlaces , 
        isDisplayThousands: editData.isDisplayThousands ? true : false, 
        isDisplayPercentage:  editData.isDisplayPercentage ? true : false, 
        suffix: editData.suffix, 
        prefix: editData.prefix, 
        format: editData.format,
        testNumber:'',
        display:'',
      },
      checkbox:{
        defaultValue: editData.defaultValue, 
        checkBoxList: editData.checkBoxList
      },
      url:{
        url:editData.url
      },
      radio:{

      },

    }
    this.fieldsFormData = formData
  },
   // 表单字段--设置--
  updateFieldsFormData(formData){
    this.fieldsFormData = formData
  },
  // 当前正在编辑的表单类型
  currentEditFormType:'scenario', // 'scenario'、'group' 、'fields'
  setCustomData(key,value){
    this[key] = value;
  },
  currentId:0,
  languageDialogVisible:false,
  // 字段基础信息
  fieldsbasicInfor:{
    groupName:'',
    groupId:'',
    scenarioId:''
  },
  setScenarioFormData(data){
    this.scenarioFormData = data
  },
  // 分组表单修改
  setgroupFormData(data){
    this.scenarioFormData = data
  },

})