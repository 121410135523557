<template>
  <div class="scenario-config-left-section-new">
    
    <div class="bd">
      <div class="section-input">
        <el-input v-model="filterText" :placeholder="$t('scenarioConfig.Input Name Search')" size="small" />
      </div>
      <div class="tree-container">
        <el-scrollbar style="height:100%">
          <div class="section-tree">
            <el-tree
              ref="treeRef"
              class="filter-tree"
              node-key="id"
              :data="scenarioList"
              :props="defaultProps"
              :filter-node-method="filterNode"
              
              :expand-on-click-node="false"
              :default-expanded-keys="defaultExpandedKeys"
              :current-node-key="currentNodeKey"
              @node-click="handleNodeClick"

              @node-drop="handleDrop"
              draggable
              :allow-drop="allowDrop"
              :allow-drag="allowDrag"
            >
              <template #default="{ node, data }">
                <div :class="[ `is-active-${data.id === currentAction.id}`,'d-flex', 'justify-content-between', 'custom-tree-node']">
                  <!-- `is-active-${data.id === nodeExpandKey}` -->
                  <div>
                    <span v-if="data.level === 'field'" class="fields-sort">
                      <i class="el-icon-menu"></i>
                    </span>
                    <span>{{ data.defaultTextName }}</span>
                  </div>
                  <div>
                    <!-- <span v-if="data.level === 'scenario'" class="fields-sort" @click.stop="sort(data)">
                      <i class="el-icon-sort"></i>
                    </span> -->
                    <template v-if="currentAction.type==='add'&& currentAction.id === data.id">
                      <el-tag 
                        :disable-transitions="true" 
                        style="height:20px;line-height:20px;background: #28C76F;color: #fff;" size="samll" 
                        type="success" 
                        round
                      >{{currentAction.type === 'add' ? $t('scenarioConfig.Adding') : '编辑中'}}</el-tag>
                    </template>
                    <template v-if="data.level !== 'field'">
                      <span class="fields-add" @click.stop="add(data)">
                        <i class="el-icon-plus"></i>
                      </span>
                    </template>
                    <span v-if="data.isDefault !== 0" class="fields-del" @click.stop="remove(data)">
                      <i class="el-icon-delete "></i>
                    </span>

                    <!-- <a @click="append(data)"> Append </a>
                    <a style="margin-left: 8px" @click="remove(node, data)"> Delete </a> -->
                  </div>
                </div>
              </template>
            </el-tree>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <!-- 排序弹窗 -->
    <fieldsSort ref="fieldsSort"></fieldsSort>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="handleDel"></deleteConfirmDialog>

  </div>
</template>
<script>
import { scenlistsect, scenlisdel, deleteScenarioGroup, deleteScenarioField, soreScenarioField } from "@/libs/api/scenario-config";
import { store } from "../store/index"
import { debounce } from 'lodash';
import gobalStore from '@/store'

import fieldsSort from './fieldsSort.vue'
import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'

export default {
  components: {
    fieldsSort,
    deleteConfirmDialog
  },
  data() {
    return {
      store,
      filterText:'',
      currentNodeKey:'',
      defaultExpandedKeys:[] ,//default-expanded-keys 默认展开的节点的 key 的数组
      defaultProps:{
        children: 'children',
        label: 'defaultTextName'
      },
      currentAction:{
        type:'', // add,edit.delete
        id:'', // 当前操作的id
        parentId:''
      },
      scenarioList:[],
      sortable:null,
      // dataList:dataList,
      deleteInfor:null
    }
  },
  created(){
    // 获取列表
    this.getData()
    store.setScenarioListGetFunction(this.getData)
  },
  mounted(){
  },
  watch: {
    filterText (val) {
      this.$refs.treeRef.filter(val);
    }
  },
  methods: {
    // 获取场景列表数据
    async getData(callbk){
      let userData = JSON.parse(localStorage.getItem('userData'))
      let params = {
        merchantId: userData.merchantId,
        textKey:"",
        scenarioKey: "",
        isEffect: 0,
        agentId: ""
      }
      let { data } = await scenlistsect(params)
      if(data.state){
        let scenarioList = data.data

        gobalStore.dispatch('verticalMenu/updateRouteList')

        scenarioList.forEach((item1,index1) => {
          item1.level='scenario'
          item1.id = 'scenario_id_'+ item1.scenarioId
          item1.children = item1.groupList
          item1.groupList.forEach((item2) => {
            item2.level='group'
            item2.id = 'group_id_'+ item1.scenarioId +'_'+ item2.groupId
            item2.parentId = 'scenario_id_'+item1.scenarioId
            item2.children = item2.fieldList
            item2.fieldList.forEach(item3 => {
              item3.groupName = item2.defaultTextName
              item3.level='field'
              item3.id = 'field_id_'+ item3.fieldId
              item3.parentId = 'group_id_'+ item1.scenarioId +'_'+ item2.groupId
              item3.scenarioIndex = index1
            })
          })
        });
        console.log('获取列表数据',scenarioList)
        if(this.scenarioList.length === 0){
          
        } else {
          console.log(this.$refs.treeRef.value)
        }
        
        // this.scenarioList = [...scenarioList]
        // this.defaultExpandedKeys = scenarioList.map((item) =>item.id )
        this.scenarioList = [...scenarioList]
        let getNodeExpandKey = this.getNodeExpandKey()
        console.log('需要默认展开的key',getNodeExpandKey)
        if(getNodeExpandKey){
          let arr=[]
          arr.push(getNodeExpandKey)
          this.defaultExpandedKeys = arr
        }

        // 清空currentAction 为空
        if(this.currentAction.type !== 'edit' &&  this.currentAction.type !== 'add'){
          this.clearAction()
        } 
        
        // 当添加时，清空右侧表单
        if(this.currentAction.type === 'add'){
          store.initCenarioFormData()
          store.clearGroupFormData()
          store.clearFieldsFormData()
          store.setScrollbarRightTop()
        }
        

        if(callbk){
          callbk()
        }
      } else {
        this.$message.error(data.message)
      }
    },
    // 搜索节点
    filterNode (value, data) {
      if (!value) return true;
      return data.defaultTextName.indexOf(value) !== -1;
    },
    // 设置当前正在进行中的操作
    setAction(type,data){
      let currentAction = {
        type: type, // add,edit.delete
        id:data.id, // 当前操作的id
        parentId:data.parentId
      }
      this.currentAction = currentAction
    },
    clearAction(){
      let currentAction = {
        type: '', // add,edit.delete
        id:'', // 当前操作的id
        parentId:''
      }
      this.currentAction =currentAction
    },
    // 判断节点能否被拖拽
    allowDrag(draggingNode) {
      // console.log('---判断节点能否被拖拽---draggingNode',draggingNode)
      if(draggingNode.level === 1 || draggingNode.level === 2){
        return false
      } else {
        return true
      }
    },
    // 拖拽时判定目标节点能否被放置。
    allowDrop(draggingNode, dropNode, type) {
      // console.log('---拖拽时判定目标节点能否被放置---draggingNode',draggingNode, dropNode, type)
      if(dropNode.level === 1){
        return false
      } else if (draggingNode.data.scenarioId !== dropNode.data.scenarioId) {
        // 跨场景拖拽
        return false
      } else if(dropNode.level === 2){
        return type === 'inner'? true :false
      } else {
        return type === 'inner'? false :true
      }
    },
    // 拖拽成功完成时触发的事件
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log('tree drop: ', draggingNode, dropNode.label, dropType);
      console.log('排序后的列表数据',this.scenarioList[draggingNode.data.scenarioIndex])

      this.soreScenarioField(this.scenarioList[draggingNode.data.scenarioIndex])
    },
    // 排序--请求api
    async soreScenarioField(args){
      try {
        let arr = []
        let dataList = args.children
        for(var i = 0;i< dataList.length;i++){
          let tepm = dataList[i].children.map(item => {
            return {
              fieldId:item.fieldId,
              groupId:dataList[i].groupId
            }
          })
          console.log('tepm',tepm)
          arr = arr.concat(tepm)
        }
        arr.forEach((item,index) => (
          item.displayOrder = index
        ))
        console.log('组装后的数据',arr)
        let params ={
          scenarioId:args.scenarioId,
          scenarioFieldList:arr
        }
        let { data } = await soreScenarioField(params)
        console.log(data)
        if(data.state){
          this.getData()
        } else {
          this.$message.error(data.message)
        }
      } catch (error) {
        
      }
    },

    // 排序 ---临时 弹窗排序
    sort(data){
      console.log('排序',data)
      this.$refs.fieldsSort.open(data)
    },
    // 需要展开的节点
    getNodeExpandKey(){
      let {type,id,parentId} = this.currentAction
      let nodeExpandKey =''
      if(type === 'add'){
        nodeExpandKey = id
      } else {
        nodeExpandKey = parentId
      }
      return nodeExpandKey
    },
    // 编辑---点事件
    handleNodeClick(data){
      this.currentNodeKey = data.id
      if(data.level === 'scenario'){
        this.scenarioEdit(data)
      } else if(data.level === 'group'){
        this.groupEdit(data)
      } else {
        this.fieldsEdit(data)
      }
      this.setAction('edit',data)  
    },
    // 添加---点事件
    add(data){
      if(data.level === 'scenario'){
        // 添加分组
        this.currentNodeKey =''
        this.$refs.treeRef.setCurrentKey('')
        this.groupAdd(data)
      } else {
        // 添加字段
        this.$refs.treeRef.setCurrentKey('')
        this.currentNodeKey =''
        this.fieldsAdd(data)
      }
      this.currentNodeKey = data.id
      this.setAction('add',data)  
    },
     // 删除---点事件
    remove(data){
      console.log('点击了tree--remove',data)
      if(data.level === 'scenario'){
        // 场景删除
        this.scenarioDel(data)
      } else if(data.level === 'group'){
        // 分组删除
        this.groupDel(data)
      } else {
        // 字段删除
        this.fieldsDel(data)
      }
      this.setAction('delete',data)  

    },

    // 以下为老代码
    // 处理场景搜索结果
    handleSearch:debounce(function(){
      store.handleSearchRes()

    },300),
    // 新增、删除处理 搜索结果
    updateSearch(){
      if(store.searchValue !== ''){
        store.handleSearchRes()
      }
    },
    // 处理删除弹窗逻辑
    handleDel(){
      let deleteInfor = this.deleteInfor;
      if(deleteInfor.delType === 'scenario'){
        this.scenlisDelApi();
      } else if(deleteInfor.delType === 'group') {
        this.groupDelApi();
      } else if(deleteInfor.delType === 'fields') {
        this.fieldsDelApi()
      }
    },
    // 场景-编辑
    scenarioEdit(scenario,scenarioIndex){
      console.log('点击左侧编辑场景',scenario,scenarioIndex)
      console.log('配置的语言信息',scenario.languageList.length)
      let { scenarioId, defaultTextName, scenarioKey, isDefault, languageList } = scenario;
      let data = {
        scenarioId,
        defaultTextName,
        scenarioKey,
        isDefault,
        languageList,
      };
      store.setScenarioFormData(data);
      store.setCustomData('currentId',scenarioId);
      store.setCustomData('currentEditFormType','scenario');
      
    },
    // 场景-删除弹窗
    async scenarioDel(scenario){
      scenario.delType = 'scenario';
      this.deleteInfor = scenario;
      // this.dialogVisible = true;
      this.$refs.deleteConfirmDialog.open()
    },
    // 场景-删除-api
    async scenlisDelApi(){
      let params = {
        scenarioId: this.deleteInfor.scenarioId,
      };
      let { data } = await scenlisdel(params);
      console.log(data);
      if(data.state){
        // this.dialogVisible = false;
        this.$refs.deleteConfirmDialog.close()
        store.initCenarioFormData();
        this.getData(() =>{

        })
        // store.getScenarioList(()=>{
        //   store.handleSearchRes()
        // });
        this.$message({
          message: this.$t('commonMessage.DeleteSucess'),
          type:'success',
        });
      } else {
        this.$message.error(data.message)
        this.$refs.deleteConfirmDialog.close()
        // this.dialogVisible = true;
      }
    },
    // 分组添加
    groupAdd(scenario,scenarioIndex){
      console.log('左侧分组添加',scenario,scenarioIndex);
      console.log('当前场景的scenarioId',scenario.scenarioId);
      let data = {
        scenarioId: scenario.scenarioId
      };
      store.setCustomData('groupBasicData',data);
      store.setCustomData('currentEditFormType','group');
      store.clearGroupFormData()
    },
    // 分组编辑
    groupEdit(group,groupIndex){
      console.log('左侧分组编辑',group,groupIndex);
      console.log('配置的语言信息',group.languageList.length)
      let {scenarioId, groupId, defaultTextName,displayOrder,isDefault,languageList} = group
      let setgroupFormData = {
        scenarioId,
        groupId,
        defaultTextName,
        displayOrder,
        isDefault,
        languageList,
      };
      // store.setGroupFormData(setgroupFormData);
      // store.setCustomData('groupFormData', setgroupFormData);
      let data = {
        scenarioId
      };
      store.setCustomData('groupBasicData',data);
      store.setGroupFormData(setgroupFormData)
      store.setCustomData('currentEditFormType','group');
      store.setCustomData('currentId',group.groupId);
    },
    // 分组删除 --弹窗
    groupDel(group){
      console.log(group);
      group.delType = 'group';
      this.deleteInfor = group;
      // this.dialogVisible = true;
      this.$refs.deleteConfirmDialog.open()
    },
    // 分组删除-api
    async groupDelApi(){
      console.log('删除信息deleteInfor', this.deleteInfor);
      let params = {
        scenarioId: this.deleteInfor.scenarioId,
        groupId: this.deleteInfor.groupId
      };
      let { data } = await deleteScenarioGroup(params);
      console.log(data);
      if(data.state){
        // this.dialogVisible = false;
        this.$refs.deleteConfirmDialog.close()
        // store.initCenarioFormData()

        this.getData()
        // store.getScenarioList(()=>{
        //   store.handleSearchRes()
        // });
        this.$message({
          message: this.$t('commonMessage.DeleteSucess'),
          type:'success',
        });
      } else {
        this.$message.error(data.message)
        this.$refs.deleteConfirmDialog.close()
        // this.dialogVisible = false;
      }
    },
    // 字段--新增
    fieldsAdd(item,index){
      console.log('添加字段',item,index);
      let data = {
        groupName: item.defaultTextName,
        groupId: item.groupId,
        scenarioId: item.scenarioId
      };
      store.setCustomData('currentEditFormType','fields');
      store.setCustomData('fieldsbasicInfor',data);
      // store.setFieldsbasicInfor(data);
      store.clearFieldsFormData();
    },
    // 字段--编辑
    fieldsEdit(item,index,groupName){
      console.log('编辑字段',item,index);
      console.log('配置的语言信息',item.languageList.length)
      let data = {
        groupName: item.groupName,
        groupId: item.groupId,
        scenarioId: item.scenarioId
      };
      // store.setFieldsbasicInfor(data);
      store.setCustomData('fieldsbasicInfor',data);
      store.setFieldsFormData(item);
      store.setCustomData('currentEditFormType','fields');
      store.setCustomData('currentId',item.fieldId);
    },
    // 字段--删除
    fieldsDel(item){
      console.log('删除字段',item);
      item.delType = 'fields';
      this.deleteInfor = item;
      // this.dialogVisible = true;
      this.$refs.deleteConfirmDialog.open()
    },
    // 字段--删除--api
    async fieldsDelApi(){
      console.log('删除信息deleteInfor', this.deleteInfor);
      let params = {
        scenarioId: this.deleteInfor.scenarioId,
        fieldId: this.deleteInfor.fieldId
      };
      let { data } = await deleteScenarioField(params);
      console.log(data);
      if(data.state){
        this.$refs.deleteConfirmDialog.close()
        // this.dialogVisible = false;
        // store.initCenarioFormData()

        this.getData()
        // store.getScenarioList(()=>{
        //   store.handleSearchRes()
        // });
        this.$message({
          message: this.$t('commonMessage.DeleteSucess'),
          type:'success',
        });
      } else {
        this.$message.error(data.message)
        this.$refs.deleteConfirmDialog.close()
        // this.dialogVisible = false;
      }
    },
    // 删除场景、分组、字段后，左侧高亮以及右侧表单
    handleAfterDel(){
      let deleteInfor = this.deleteInfor;
      if(deleteInfor.type === store.currentEditFormType){

      }

    }
  }
}
</script>
<style lang="scss">
  .scenario-config-left-section-new{
    position: relative;
    .tree-container{
      height: 70vh;
      // position:absolute;
      // top: 0px;
      // left: 0;
    }
    .bd{
      .section-input{
        padding: 10px;
      }
      .section-tree{
        padding: 10px;
      }
      .current-add{
        border:1px solid #28C76F;
      }
    }
    .custom-tree-node{
      width: 100%;
      padding: 10px 0;
      box-sizing: border-box;
    }
    .custom-tree-node.is-active-true{
      // background: #35B871;
      // color: #fff;
      color:  #35B871;
    }
    ::v-deep .el-tree-node__content {
      height: 36px!important;
    }
    .fields-sort{
      position: absolute;
      top: 50%;
      left: 40px;
      transform: translateY(-50%);
    }
    .fields-add,
    .fields-del{
      padding: 10px;
    }
    .el-tree.filter-tree > .el-tree-node{
      // border: 1px solid #e4e7ed;
      // box-shadow: 0 0 12px 0 rgb(0,0,0,.12);
      border-radius: 4px;
      margin-bottom: 10px;
      
      .el-tree-node__content{
        height: 36px!important;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          left: 10px;
          right: 10px;
          bottom: 0;
          height: 1px;
          border-bottom: 1px solid #EFEFEF;
        }
      }
    }
    .el-tree.filter-tree > .el-tree-node > .el-tree-node__content{
      background: whitesmoke;
      border-radius: 4px;
      &::after{
        display: none;
      }
    }

    // 重置样式
    ::v-deep .el-tag {
      height: 20px;
      line-height: 20px;
    }
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
      color: #28C76F;
    }
    .el-dialog{
      overflow: hidden;
    }
    .el-scrollbar__wrap{
      overflow-x: hidden;
    }
  }
  
  
  .scenario-config-left-section{
    // padding: 0 1.5rem;
    // background: #fff;
    border-radius: 2px;
    // height: 500px;
    // overflow-y: scroll;
    position: absolute;
    left:0;
    right:0;
    top: 0;
    bottom: 10px;
    .scenario-search{
      padding: 10px;
      // background: #fff;
      margin-bottom: 10px;
      // box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
      border-radius: 12px;
      .scenario-search-btn{
        background: #3461FF;
        color: #fff;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0 3px 3px 0;
        padding: 8px 20px;
        cursor: pointer;
      }

    }
    .flex-1{
      flex: 1;
    }
    .scenario-item{
      padding: 0 1.5rem;
      // background: #fff;
      margin: 0 10px;
      margin-bottom: 10px;
      // background: #f4f4f4;
      // box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
      border-radius: 2px;
      border: 1px solid #dedede;
      overflow: hidden;
      .fields-add{
        padding: 10px;
        cursor: pointer;
      }
      .fields-edit{
        padding: 1rem 0.6rem;
        cursor: pointer;
      }
      .fields-del{
        padding: 1rem 0.6rem;
        cursor: pointer;
      }
    }
    .scenario-item:last-child{
      margin-bottom: 0px;
    }
    .scenario-item-hd{
      display: flex;
      align-items: center;
      height:3.6rem;
      background: #F0F0F0;
      margin: 0 -1.5rem;
      padding: 0 1.5rem 0 0.5rem;
      cursor: pointer;
    }
    .scenario-item-hd.ac{
      background: #EDFFF5;
    }
    .scenario-ac{
      background: #EDFFF5;
    }
    .fields-group{
      position: relative;
      // cursor: grab;
      // cursor: move;
      .table-ic{
        display: inline-block;
        width: 8px;
        height: 13px;
        margin-right: 6px;
      }
      .fields-group-hd{
        height: 3.6rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-top: 1px solid rgb(235, 233, 241);
      }
      .fields-group-hd.ac,
      .fields-item.ac{
        color: #28C76F;
        // background: #EDFFF5;
      }
      .fields-item{
        height: 3.6rem;
        padding: 0 0 0 2.2rem;
        position: relative;
        // cursor: grab;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-top: 1px solid rgb(235, 233, 241);
      }
      .fields-item:hover{
        background: #EBEEF5;
      }
    }
    .fields-group:last-child::after{
      display: none;
    }

    // 重置样式
    .el-dialog{
      overflow: hidden;
    }
    .el-dialog__body{
      padding: 30px;
    }
    .el-scrollbar__wrap{
      overflow-x: hidden;
    }
  }
  .scenarioLeft-dialog-footer{
    margin-bottom: -40px;
    display: flex;
    .el-button--default{
      flex: 1;
      margin: 0 !important;
      border-radius: 0;
      padding: 16px 20px;
    }
  }
  
</style>