<template>
  <div class="page-location">{{location}}</div>
</template>
<script>
export default {
  props: {
    location: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
    }
  },
}
</script>
<style lang="scss" scoped>
  .page-location{
    height: 60px;
    line-height: 60px;
    margin-top: -1.2rem;
  }
</style>