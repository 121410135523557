import router from "../axios";
import qs from 'qs'
import config from "../../config/index"
const { baseUrl } = config;

function scenlistsect(data) {
    //获取单位转换列表
    return router({
        url: baseUrl + "scenario/v1/getScenarioList",
        params: data,
        method: "GET",
    });
}

function scenlisdel(data) {
    //删除场景
    return router({
        url: baseUrl + "scenario/v1/deleteScenario",
        params: data,
        method: "POST",
    });
}

function scenlistsecst(data) {
    //删除场景组
    return router({
        url: baseUrl + "scenario/v1/deleteScenarioGroup",
        params: data,
        method: "POST",
    });
}

function sechilidels(data) {
    //删除场景字段
    return router({
        url: baseUrl + "scenario/v1/deleteScenarioField",
        params: data,
        method: "POST",
    });
}

function addscenlis(data) {
    //添加场景
    return router({
        url: baseUrl + "scenario/v1/createScenario",
        data: data,
        method: "POST",
    });
}

function getListli() {
    //获取语言列表
    return router({
        url: baseUrl + "language/v1/getList",
        method: "POST",
    });
}

function switlin(data) {
    //切换语言
    return router({
        url: baseUrl + "user/v1/changeLanguage",
        params: data,
        method: "POST",
    });
  }
  // function createScenarioGroup(data) {
  //   //获取语言列表
  //   return router({
  //     url: baseUrl+"scenario/v1/createScenarioGroup",
  //     data:data,
  //     method: "POST",
  //   });
  // }
  function unitcreate(data) {
    //单位转换添加
    return router({
        url: baseUrl + "unit/v1/create",
        params: data,
        method: "POST",
    });
}

function unitget(data) {
    //单位转换详情
    return router({
        url: baseUrl + "unit/v1/get",
        params: data,
        method: "GET",
    });
}

function unitupdate(data) {
    //单位转换修改
    return router({
        url: baseUrl + "unit/v1/update",
        data: data,
        method: "POST",
    });
}

function getAllTagType(data) {
    //单位转换修改
    return router({
        url: baseUrl + "/tag/v1/getAllTagType",
        method: "POST",
    });
}

function customerdelete(data) {
    //单位转换修改
    return router({
        url: baseUrl + "customer/v1/delete",
        params: data,
        method: "POST",
    });
}

function customerget(data) {
    //单位转换修改
    return router({
        url: baseUrl + "/customer/v1/get",
        params: data,
        method: "GET",
    });
}

function whiteList(data) {
    //获取白名单列表
    return router({
        url: baseUrl + "whiteList/v1/getList",
        data: data,
        method: "POST",
        header: {
            'Content-Type': 'application/json'
        }
    });
}
// 场景配置--获取素材
function getImgList(data) {
    return router({
        url: baseUrl + "image/v1/getList",
        params: data,
        method: "GET",
    });
}

function whiteListsaveadd(data) {
    return router({
        url: baseUrl + "whiteList/v1/save",
        data: data,
        method: "POST",
        header: {
            'Content-Type': 'application/json'
        }
    });
}

function whiteListbatchPhysicalDelete(data) {
    return router({
        url: baseUrl + "whiteList/v1/batchPhysicalDelete",
        data: data,
        method: "POST",
        header: {
            'Content-Type': 'application/json'
        }
    });
}

export {
    scenlistsect,
    scenlisdel,
    scenlistsecst,
    sechilidels,
    addscenlis,
    getListli,
    switlin,
    unitcreate,
    unitget,
    unitupdate,
    getAllTagType,
    customerdelete,
    customerget,
    whiteList,
    getImgList,
    whiteListsaveadd,
    whiteListbatchPhysicalDelete
}