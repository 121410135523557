<template>
  <div class="scenario-form-component">
    <div class="scenario-form-component-hd">
      <img class="scene-title-icon" src="@/assets/img/scene_title_icon.png" />
      <span style="margin-left: 10px;">{{store.fieldsFormData.common.fieldId ? $t('scenarioConfig.Edit Field') : $t('scenarioConfig.Add Field')}}</span>
      <el-divider />
    </div>
    <div class="scenario-form-component-bd">
      <el-form
        ref="fieldsFormData" 
        style="width:100%;"
        label-width="190px"
        :model="store.fieldsFormData.common"
        size="small"
        :rules="rules"
      >
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <div class="section-tit">
                {{$t('scenarioConfig.Common')}}
              </div>
            </template>
            <!-- <div class="scenario-form-tit">{{$t('scenarioConfig.Common')}}</div> -->
            <div class="scenario-form-cont">
              <el-form-item 
                prop="groupName" 
                :label="$t('scenarioConfig.Group')"
              >
                <el-input :value="store.fieldsbasicInfor.groupName" disabled />
              </el-form-item>
              <el-form-item 
                prop="type" 
                :label="$t('scenarioConfig.Field type')"
              >
                <el-select v-model="store.fieldsFormData.common.type" placeholder="" :disabled="store.fieldsFormData.common.fieldId ? true: false" >
                  <el-option label="text" value="text" />
                  <el-option label="number" value="number" />
                  <el-option label="date" value="date" />
                  <el-option label="img" value="img" />
                  <el-option label="checkbox" value="checkbox" />
                  <el-option label="url" value="url" />
                  <!-- <el-option label="radio" value="radio" /> -->
                </el-select>
              </el-form-item>
              <el-form-item 
                prop="fieldKey"
                :label="$t('scenarioConfig.Field Key')"
              >
                <el-input
                  placeholder=""
                  :disabled="store.fieldsFormData.common.isDefault ? false :true"
                  v-model="store.fieldsFormData.common.fieldKey" 
                />
              </el-form-item>
              <el-form-item 
                prop="defaultTextName"
                :label="$t('scenarioConfig.English Name')"
              >
                <el-input  
                  placeholder=""
                  :disabled="store.fieldsFormData.common.isDefault ? false :true"
                  v-model="store.fieldsFormData.common.defaultTextName" 
                />
              </el-form-item>
              <el-form-item 
                :label="$t('scenarioConfig.Language')"
                prop="language"
              >
                <el-input  placeholder="" readonly>
                  <template #append>
                    <!-- <div>添加</div> -->
                    <div>{{$t('scenarioConfig.Details')}}...</div>
                    <div class="scenario-form-language" @click="selectLanguage">{{$t('scenarioConfig.Details')}}...</div>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('scenarioConfig.Show In Template')">
                <el-switch v-model="store.fieldsFormData.common.showInTemplate" />
              </el-form-item>
              <el-form-item :label="$t('scenarioConfig.Visible')">
                <el-switch v-model="store.fieldsFormData.common.isVisible" />
              </el-form-item>
              <el-form-item :label="$t('scenarioConfig.Can Edit')">
                <el-switch v-model="store.fieldsFormData.common.canEdit" />
              </el-form-item>
              <el-form-item :label="$t('scenarioConfig.Is Required')">
                <el-switch v-model="store.fieldsFormData.common.isRequired" />
              </el-form-item>
              <el-form-item :label="$t('scenarioConfig.Batch Modify')">
                <el-switch v-model="store.fieldsFormData.common.batchUpdateVisible" />
              </el-form-item>
            </div>
          </el-collapse-item>
        
          <el-collapse-item name="2">
            <template slot="title">
              <div class="section-tit">
                {{$t('scenarioConfig.Field type Info')}}
              </div>
            </template>
            <!-- <div class="scenario-form-tit">{{$t('scenarioConfig.Field type Info')}}</div> -->
            <!-- 文本 -->
            <div v-if="store.fieldsFormData.common.type == 'text'" class="scenario-form-cont">
              <el-form-item 
                :label="$t('scenarioConfig.Default')"
                prop="defaultValue"
              >
                <el-input
                  placeholder="" 
                  v-model="store.fieldsFormData.common.defaultValue" 
                  clearable
                />
              </el-form-item>
              <el-form-item :label="$t('scenarioConfig.Max length')" prop="maxLength">
                <el-input
                  placeholder="" 
                  v-model.number="store.fieldsFormData.common.maxLength"
                  autocomplete="off"
                  clearable
                />
                <!-- <el-input-number
                  :min="1"
                  :max="9999"
                  controls-position="right"
                  placeholder="" 
                  v-model="store.fieldsFormData.text.maxLength" 
                /> -->
              </el-form-item>
            </div>
            <!-- 日期 -->
            <div v-else-if="store.fieldsFormData.common.type == 'date'" class="scenario-form-cont">
              <el-form-item :label="$t('scenarioConfig.Format')" prop="format">
                <el-input
                  placeholder="" 
                  v-model="store.fieldsFormData.date.format" 
                >
                  <template #append>
                    <div class="scenario-form-date-format" @click="dialogVisibleDate = true">
                      <img  src="@/assets/img/date-detail.png" alt="" style="width: 20px;height: 20px;" />
                    </div>
                  </template>
              </el-input>
              </el-form-item>
            </div>
            <!-- url -->
            <div v-else-if="store.fieldsFormData.common.type == 'url'" class="scenario-form-cont">
              <el-form-item :label="$t('scenarioConfig.URL')" prop="url">
                <el-input
                  placeholder="" 
                  v-model="store.fieldsFormData.url.url" 
                />
              </el-form-item>
            </div>
            <!-- 数字 -->
            <div v-else-if="store.fieldsFormData.common.type == 'number'" class="scenario-form-cont">
              <el-form-item label="">
                <el-radio-group v-model="store.fieldsFormData.number.isCustom">
                  <el-radio :label="0" >{{$t('scenarioConfig.Normal')}}</el-radio>
                  <el-radio :label="1" >{{$t('scenarioConfig.Custom')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('scenarioConfig.Default')" prop="defaultValue">
                <el-input
                  placeholder="" 
                  v-model="store.fieldsFormData.number.defaultValue" 
                />
              </el-form-item>
              <template v-if="store.fieldsFormData.number.isCustom === 0">
                <el-form-item :label="$t('scenarioConfig.Keep decimal places')" prop="keepDecimalPlaces">
                  <el-input-number
                    v-model="store.fieldsFormData.number.keepDecimalPlaces"
                    :min="0"
                    :max="10"
                    controls-position="right"
                  />
                </el-form-item>
                <el-form-item :label="$t('scenarioConfig.Display thousands')">
                  <el-switch v-model="store.fieldsFormData.number.isDisplayThousands" @change="handleDisplayThousands" />
                </el-form-item>
                <el-form-item :label="$t('scenarioConfig.Display as a percentage')">
                  <el-switch v-model="store.fieldsFormData.number.isDisplayPercentage"  @change="handleDisplayPercentage" />
                </el-form-item>
                <el-form-item :label="$t('scenarioConfig.Suffix')" prop="suffix">
                  <el-input
                    placeholder="" 
                    v-model="store.fieldsFormData.number.suffix" 
                  />
                </el-form-item>
                <el-form-item :label="$t('scenarioConfig.Prefix')" prop="prefix">
                  <el-input
                    placeholder="" 
                    v-model="store.fieldsFormData.number.prefix" 
                  />
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item :label="$t('scenarioConfig.Type')" prop="format">
                  <el-select v-model="store.fieldsFormData.number.format" placeholder="" >
                    <el-option label="0" value="custom1" />
                    <el-option label="0.00" value="custom2" />
                    <el-option label="#,##0" value="custom3" />
                    <el-option label="#,##0.00" value="custom4" />
                    <el-option label="¥#,##0;¥-#,##0" value="custom5" />
                    <el-option label="¥#,##0.00;¥-#,##0.00" value="custom6" />
                    <el-option label="$#,##0_);($#,##0)" value="custom7" />
                    <el-option label="$#,##0.00_);($#,##0.00)" value="custom8" />
                    <!-- <el-option label="0%" value="custom9" />
                    <el-option label="0.00%" value="custom10" /> -->
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('scenarioConfig.Test Number')" prop="testNumber">
                  <el-input
                    placeholder="" 
                    v-model="store.fieldsFormData.number.testNumber" 
                  />
                </el-form-item>
                <el-form-item :label="$t('scenarioConfig.Display')" prop="display">
                  <el-input
                    placeholder="" 
                    v-model="computedDisplay" 
                    readonly
                  />
                </el-form-item>

                <!-- <el-form-item label="Display" prop="defaultValue">
                  <el-input
                    placeholder="Default" 
                    v-model="store.fieldsFormData.number.defaultValue"
                    readonly
                  />
                </el-form-item> --> 
              </template>   

            </div>
            <!-- 图片 -->
            <div v-else-if="store.fieldsFormData.common.type == 'img'" class="scenario-form-cont">
              <el-form-item :label="$t('scenarioConfig.Source')">
                <el-radio-group v-model="store.fieldsFormData.img.imageType">
                  <el-radio label="0" > {{$t('scenarioConfig.URL')}}</el-radio>
                  <el-radio label="1">{{$t('scenarioConfig.Material Library')}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="$t('scenarioConfig.Default')" prop="defaultValueImg">
                <div class="d-flex">
                  <el-input 
                    placeholder=""
                    v-model="store.fieldsFormData.img.defaultValue" 
                    :title="store.fieldsFormData.img.defaultValue"
                  />
                  <el-button style="margin-left:10px" type="primary" size="small" @click="showImgDialog">{{$t('scenarioConfig.Browse')}}...</el-button>
                </div>
                
                <!-- <el-input  
                  placeholder=""
                  v-model="store.fieldsFormData.img.defaultValue" 
                  readonly
                >
                  <template #append>
                    <div>Details...</div>
                    <div class="scenario-form-language" @click="showImgDialog">Browse...</div>
                  </template>
                </el-input> -->
              </el-form-item>
              <!-- <template v-if="store.fieldsFormData.img.imageType === 'url'">
                <el-form-item label="Default" prop="defaultValue">
                  <el-input 
                    placeholder=""
                    v-model="store.fieldsFormData.img.defaultValue" 
                  />
                </el-form-item>
              </template> -->

              <!-- <template v-if="store.fieldsFormData.img.imageType === 'materialLibrary'">
                <el-form-item label="Default" prop="defaultValue">
                  <el-input  
                    placeholder=""
                    v-model="store.fieldsFormData.img.defaultValue" 
                    readonly
                  >
                    <template #append>
                      <div>Details...</div>
                      <div class="scenario-form-language" @click="showImgDialog">Browse...</div>
                    </template>
                </el-input>
                </el-form-item>
              </template> -->
            </div>

            <!-- 多选 -->
            <div v-else-if="store.fieldsFormData.common.type == 'checkbox'" class="scenario-form-cont">
              <el-form-item :label="$t('scenarioConfig.Default')" prop="defaultValue">
                <el-input
                  placeholder=""
                  v-model="store.fieldsFormData.checkbox.defaultValue"
                  readonly
                />
              </el-form-item>
              <el-form-item 
                :label="$t('scenarioConfig.Option')"
                v-for="(checkbox,checkboxIndex) in store.fieldsFormData.checkbox.checkBoxList" 
                :key="checkboxIndex"
              >
                <el-input
                  placeholder=""
                  v-model="checkbox.text" 
                >
                  <template #prepend>
                    <el-checkbox 
                      v-model="checkbox.checked" 
                      label="" 
                      :disabled="checkbox.text ? false : true"
                      @change="setOptionDefaultValue"
                    />
                  </template>
                  <template #append>
                    <div v-if="checkboxIndex == 0" style="padding: 7px 10px;" @click="optionAdd">
                      <i class="el-icon-plus"></i>
                    </div>
                    <div v-else style="padding:7px 10px;"  @click="optionDel(checkboxIndex)">
                      <i class="el-icon-delete"></i>
                    </div>
                  </template>
                </el-input>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
          

        <!-- 操作按钮 -->
        <div class="scenario-form-cont">
          <el-form-item>
            <el-button color="#28C76F" type="success" @click="submitFieldsForm">{{$t('button.Save')}}</el-button>
          </el-form-item>
        </div>
      
      </el-form>
    </div>

    <!-- 选择语言 -->
    <languageSelect 
      :visible="dialogVisible"
      :languageList="store.fieldsFormData.common.languageList"
      @close="closeLanguage"
      @message="setLanguageConfig"
    >
    </languageSelect>

    <!-- 日期展示弹窗 -->
    <dateRuleDialog 
      :dialogVisible="dialogVisibleDate" 
      @handleClose="handleClose" 
    />

    <!-- 选择图片 -->
    <imgSelect 
      @handleConfirm="selectImg"
      ref="selectImgRef"
    >
    </imgSelect>
  </div>
</template>
<script>
import { checkKeyRegex } from "@/utils/common";
// 接口
import { createScenarioField, updateScenarioField } from "@/libs/api/scenario-config";
import languageSelect from './languageSelect.vue'
// import imgSelect from './imgSelect.vue'
import imgSelect from '@/components/select-img/index'
import { store } from "../store/index"
import dateRuleDialog from '@/components/date-rule-dialog'
import handleNumber from './customNumber'
let loading;

export default {
  components: {
    languageSelect,
    imgSelect,
    dateRuleDialog
  },
  props: {
  },
  data() {
    return {
      activeNames:["1","2"],
      store,
      rules: {
        fieldKey: [
          { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
          { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'},
          { validator: this.checkCode, trigger: 'blur' }
        ],
        defaultTextName: [
          { required: true, message: this.$t('formValidate.required fields') , trigger: 'blur'},
          { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
        ],
        defaultValue:[
          { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
        ],
        maxLength:[
          // { type: 'number', message: this.$t('templateInfo.ErrorMessage2'), trigger: 'blur'},
          { validator: this.checkNumber, trigger: 'blur' }
          // { max: 1000, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
        ],
      },
      dialogVisible:false,
      dialogVisibleDate:false,
      isModifyLanguage: false
    }
  },
  created(){
    // this.getData()
  },
  computed:{
    computedDisplay:function(){
      let rule = store.fieldsFormData.number.format;
      let num = store.fieldsFormData.number.testNumber
      console.log('设置的规则是：',rule)
      if(!rule || !num) return '';
      return handleNumber[rule](num)
    }
  },
  methods: {
    // handleDisplayThousands
    handleDisplayThousands(val){
      console.log('--handleDisplayThousands--',val)
      if(val){
        let fieldsFormData = {...store.fieldsFormData}
        fieldsFormData.number.isDisplayPercentage = false
        store.updateFieldsFormData(fieldsFormData)
      }
    },
    // 处理数字
    handleDisplayPercentage(val){
      console.log('--handleDisplayPercentage--',val)
      if(val){
        let fieldsFormData = {...store.fieldsFormData}
        fieldsFormData.number.isDisplayThousands = false
        store.updateFieldsFormData(fieldsFormData)
      }
    },
    // 校验 --- code
    checkCode(rule,value,callback){
      console.log('code校验value,rule',value,rule,callback)
      if (!value) {
        return callback(new Error('Please input the Field Key'))
      } else {
        let isKey = checkKeyRegex(value)
        if(!isKey){
          return callback(new Error(this.$t('scenarioConfig.ErrorMessage1')))
        }else {
          callback()
        }
      }
    },
    // 校验--maxLenght
    checkNumber(rule,value,callback){
      if (!value) {
        callback()
      } else {
        var type = typeof(value)
        if(type !== 'number'){
          return callback(new Error(this.$t('templateInfo.ErrorMessage2')))
        }
        if(value<1 || value>9999){
          return callback(new Error(this.$t('scenarioConfig.ErrorMessage2')))
        } else {
          callback()
        }
      }
      
    },
    // 表单校验
    submitFieldsForm(){
      this.isModifyLanguage = false
      console.log('字段表单内容 ',this.$refs.fieldsFormData)
      console.log('store.fieldsFormData ',store.fieldsFormData.common)
      loading = this.$loading({
        text:"loading...",
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)',
      })
      
      this.activeNames=["1","2"]
      this.$refs.fieldsFormData.validate( valid => {
        if (valid) {
          if(store.fieldsFormData.common.fieldId){
            this.fieldUpdate()
          } else {
            this.fieldAdd() 
          }
          
        } else {
          loading.close()
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 处理表单字段
    handleFormFields(){
      let params = {
        ...store.fieldsFormData.common,
        ...store.fieldsFormData.text,
        ...store.fieldsFormData.date,
        groupId: store.fieldsbasicInfor.groupId,
        scenarioId: store.fieldsbasicInfor.scenarioId,
        showInTemplate: store.fieldsFormData.common.showInTemplate ? 1: 0,
        isVisible: store.fieldsFormData.common.isVisible ? 1: 0, //is visible 0:不可见 1:可见, 默认可见
        canEdit: store.fieldsFormData.common.canEdit ? 1: 0, // can edit 0:不可编辑 1:可编辑,默认可编辑
        isRequired: store.fieldsFormData.common.isRequired ? 1: 0, // is required 0:不是必填 1:必填，默认非必填
        batchUpdateVisible: store.fieldsFormData.common.batchUpdateVisible, // 是批量修改可见，默认：否
      }
      if(store.fieldsFormData.common.type === 'number'){
        params = {
          ...params,
          ...store.fieldsFormData.number,
          isDisplayPercentage: store.fieldsFormData.number.isDisplayPercentage ? 1 :0,
          isDisplayThousands: store.fieldsFormData.number.isDisplayThousands ? 1 : 0,
        }
      } else if(store.fieldsFormData.common.type === 'img'){
        params = {
          ...params,
          ...store.fieldsFormData.img
        }
      } else if(store.fieldsFormData.common.type === 'checkbox'){
        params = {
          ...params,
          ...store.fieldsFormData.checkbox
        }
      } else if(store.fieldsFormData.common.type === 'url'){
        params = {
          ...params,
          defaultValue:store.fieldsFormData.url.url,
          ...store.fieldsFormData.url
        }
      }
      return params
    },
    // 字段新增
    async fieldAdd(){
      // store.fieldsbasicInfor.scenarioId
      let params = this.handleFormFields()
      console.log('请求参数', params)
      let { data } = await createScenarioField(params)
      console.log('【新增字段】接口返回', data)
      if(data.state){
        this.$message({
          message: this.$t('commonMessage.CreateSucess'),
          type:'success',
        });
        store.reloadScenarioListGetFunction()
        // store.getScenarioList(() => {
        //   store.handleSearchRes()
        // })
      } else {
        this.$message.error(data.message,);
      }
      setTimeout(() => {
        loading.close()
      },500)

    },
    //字段修改
    async fieldUpdate(){
      let params = this.handleFormFields()
      params.updateType = this.isModifyLanguage ? 1: 0
      let { data } = await updateScenarioField(params)
      console.log('修改字段接口返回', data)
      if(data.state){
        this.$message({
          message: this.$t('commonMessage.ModifySucess'),
          type:'success',
        });
        store.reloadScenarioListGetFunction()
        // store.getScenarioList(() => {
        //   store.handleSearchRes()
        // })
      } else {
        this.$message.error(data.message,);
      }
      setTimeout(() => {
        loading.close()
      },500)

    },
    // 多选选项--添加
    optionAdd(){
      console.log('添加下拉选项')
      store.fieldsFormData.checkbox.checkBoxList.push({
        checked: false,
        checkBoxId:'', // 新建不传，修改必传
        text:'', // 文本
        displayOrder: 0, // 排序
      })
    },
    // 多选选项--删除
    optionDel(index){
      store.fieldsFormData.checkbox.checkBoxList.splice(index,1)
      this.setOptionDefaultValue()
    },
    // 设置defaultValue
    setOptionDefaultValue(){
      let strArr = []
      store.fieldsFormData.checkbox.checkBoxList.forEach(function(value){
        if(value.checked){
          strArr.push(value.text)
        }
      })
      store.fieldsFormData.checkbox.defaultValue = strArr.join(',')
    },
    // 选择语言弹窗
    selectLanguage(){
      this.dialogVisible = true;
      console.log('选择语言',this.dialogVisible)
    },
    // 关闭语言弹窗
    closeLanguage(){
      console.log('测试closeLanguage')
      this.dialogVisible = false;
    },
    // 选择语言弹窗--确定
    setLanguageConfig(data){
      console.log('配置的国际化语言',data)
      store.fieldsFormData.common.languageList = data
      this.isModifyLanguage = true
      if(store.fieldsFormData.common.fieldId){
        this.fieldUpdate()
      }
    },
    // 关闭日期弹窗
    handleClose() {
      this.dialogVisibleDate = false
    },
    // 选择图片--弹窗
    showImgDialog(){
      this.$refs.selectImgRef.open()
    },
    // 选择图片
    selectImg(obj){
      console.log('选择的图片',obj)
      store.fieldsFormData.img.defaultValue = obj.imageUrl
    }

  }
}
</script>
<style lang="scss" scoped>
.scenario-form-component{
  .scene-title-icon{
    width: 15px;
    height: 16px;
  }
  .scenario-form-tit{
    height: 40px;
    line-height: 40px;
    margin: 0 20px 20px;
    padding-left: 26px;
    background: #F4F4F4;
    border-radius: 4px;
  }
  .scenario-form-cont{
    // width:600px; 
    width: 31vw;
    margin:0 auto;
    padding-top: 20px;
    .el-input-number .el-input__inner{
      text-align: left;
    }
  }
  .scenario-form-cont.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    width: auto;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    z-index: 200;
  }
  .scenario-form-component-hd{
    height: 50px;
    line-height: 50px;
    padding: 0 28px;
    .el-divider--horizontal{
      margin: 0;
    }
  }
  .scenario-form-component-bd{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .scenario-form-language{
    background: #3461FF;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 3px;
    padding: 7px 20px;
    cursor: pointer;
  }
  .scenario-form-date-format{
    padding: 5px 20px;
    margin: 0 -20px;
  }

  .el-collapse-item{
    margin: 0 20px 20px 20px;
  }
  .section-tit{
    position: relative;
    font-weight: 600;
    background: whitesmoke;
    .flag{
      position: absolute;
      width: 4px;
      height: 18px;
      border-radius: 2px;
      background: #35B871;
      top: 0px;
      bottom: 0px;
      left: -10px;
      margin: auto;
    }
  }

  // 重置elementUI样式
  ::v-deep  .el-collapse{
    border: none;
  }
  ::v-deep .el-collapse-item__wrap{
    border: none;
  }
  ::v-deep .el-collapse-item__content{
    padding: 0;
  }
  ::v-deep .el-collapse-item__header {
    height: 30px;
    line-height: 30px;
    // background-color: #f3f3f3;
    background: whitesmoke;
    border: none;
    border-radius: 4px;
    padding: 20px;
    font-size: 14px;
  }
  .el-form-item__label {
    color: #999;
    font-size: 14px!important;
  }
  .el-button--success{
    background: #35B871;
    box-shadow: 0px 1px 15px 0px rgba(53,184,113,0.55);
  }
  .el-input__inner:focus {
    border-color:#35B871;
  }
  .el-button{
    width: 100px;
  }
  .el-select,
  .el-input-number{
    width: 100%;
  }
  .el-input-number .el-input__inner{
    text-align: left !important;
  }
  ::v-deep .el-input-number .el-input__inner{
    text-align: left;
  }
}
</style>